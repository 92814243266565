/**
 * Event that has no arguments.
 */
class LanguageEvent {
    private _listeners: EventListener[] = [];

    public addListener(listener: EventListener) {
        this._listeners.push(listener);
    }

    public removeListener(listener: EventListener) {
        const index = this._listeners.indexOf(listener);
        if (index !== -1) {
            this._listeners.splice(index, 1);
        }
    }

    public removeAllListeners() {
        this._listeners = [];
    }

    public invoke() {
        const listeners = [...this._listeners];
        listeners.forEach(l => {
            l();
        });
    }
}

/**
 * Signature of event listener that has no arguments.
 */
type EventListener = () => void;

const ProductList_Region: string = '__traceability-region-product-list__';

export interface ILanguageOption {
    name: string;
    code: string;
}

export namespace Language {
    export var Languages: ILanguageOption[] = JSON.parse('__language_options__');
    export var defaultLanguageCode = '__language-defaultcode__';

    var _currentLanguage: ILanguageOption = null;
    export var onLanguageChanged = new LanguageEvent();

    export async function init(): Promise<void> {
        let countryCode = ProductList_Region.slice(-2);
        if (countryCode === 'zh')
            countryCode = 'cn'
        // Language options are dependent on what region this build is for.
        Languages = Languages.filter(l => l.code.endsWith(countryCode));

        // Load current language.
        const languageCode = localStorage.getItem('language_code');

        const selectedLanguage = Languages.find(l => l.code === languageCode);
        if (selectedLanguage) {
            _currentLanguage = selectedLanguage;
        } else {
            // Set language to the default language.
            setLanguage(defaultLanguageCode);
        }
    }

    export function currentLanguage(): ILanguageOption {
        return _currentLanguage;
    }

    /**
     * Set the desired language.
     * @param language Can either be a language option object or a language code string.
     */
    export function setLanguage(language: ILanguageOption | string): void {
        let langOption: ILanguageOption;

        if (typeof language === 'string') {
            langOption = Languages.find(l => l.code === language);
        } else {
            langOption = Languages.find(l => JSON.stringify(l) === JSON.stringify(language));
        }

        if (!langOption) {
            // Lanaugage does not appear to be supported. Set language to default.
            console.error(`[Language] Language ${JSON.stringify(language)} is not supported. Defaulting to the first language option available.`);
            langOption = Languages[0];
        }

        if (_currentLanguage !== langOption) {
            _currentLanguage = langOption;
            localStorage.setItem('language_code', langOption.code);

            onLanguageChanged.invoke();
        }
    }
}