import { Language } from './Language';
import { loadingScreen } from './LoadingScreen';

async function init() {
    // Initialize Languages module.
    Language.init();
    Language.onLanguageChanged.addListener(onLanguageChanged);

    // Initialize and show the loading screen immediately for first-paint.
    const LocalizedLoadingText: { [key: string]: string } = {
        'en-us': 'Loading',
        'zh-cn': '载入中',
        'id-id': 'Memuat'
    }

    loadingScreen
        .setVisible(true)
        .setTitle((
            (): string => {
                const languageCode = Language.currentLanguage().code;
                if (languageCode in LocalizedLoadingText) {
                    return LocalizedLoadingText[languageCode];
                } else {
                    console.warn(`Language code ${languageCode} is not implemented for localized loading text. Defaulting to English.`);
                    return LocalizedLoadingText['en-us'];
                }
            })()
        )
        .setProgressVisible(true)
        .setProgressIndeterminate()

    const { start } = await import('./Application');
    start();
}

function onLanguageChanged(): void {
    // Refresh the page when the language changes.
    location.reload();
}

init();