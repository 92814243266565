import { CircleProgressBar } from "./CircleProgressBar";

export type ProgressFrameCallback = () => number;

class LoadingScreen {
    private _visible: boolean;
    private _progressVisible: boolean;
    private _rootElement: HTMLElement;
    private _loadingElement: HTMLElement;
    private _messageElement: HTMLElement;
    private _circleProgressBar: CircleProgressBar;
    private _onProgressAnimate: ProgressFrameCallback;
    
    constructor() {
        this._rootElement = document.getElementById('loading-screen');

        this._loadingElement = document.getElementById('loading-text');
        this._loadingElement.textContent = null;

        this._messageElement = document.getElementById('loading-message');
        this._messageElement.textContent = null;

        const progressCanvas = document.getElementById('loading-progress-circle') as HTMLCanvasElement;
        this._circleProgressBar = new CircleProgressBar({
            canvas: progressCanvas,
            fillColor: '#8ec640',
            color: '#e3f1cf',
            thickness: 3,
        });

        // Initialize the loading screen to hidden state.
        this.setVisible(false);
        this.setProgressVisible(false);

        this._animate = this._animate.bind(this);
        this._animate();

    }

    setVisible(visible: boolean): LoadingScreen {
        this._visible = visible;
        this._rootElement.style.visibility = visible ? 'visible' : 'hidden';
        return this;
    }

    setTitle(title: string): LoadingScreen {
        this._loadingElement.textContent = title;
        return this;
    }

    setProgress(progress: number): LoadingScreen {
        this._circleProgressBar.setProgress(progress);
        this._onProgressAnimate = null;
        return this;
    }

    setProgressVisible(visible: boolean): LoadingScreen {
        this._progressVisible = visible;
        this._circleProgressBar.parameters.canvas.style.visibility = visible ? 'inherit' : 'hidden';
        return this;
    }

    setProgressIndeterminate(): LoadingScreen {
        this._circleProgressBar.playIndeterminateAnimation();
        return this;
    }
    
    setProgressAnimationLoop(onProgressAnimate: ProgressFrameCallback): LoadingScreen {
        this._onProgressAnimate = onProgressAnimate;
        return this;
    }

    private _animate(): void {
        if (this._visible && this._progressVisible && this._onProgressAnimate) {
            let progress: number;
    
            try {
                progress = this._onProgressAnimate();
            } catch (e) {
                console.error(`[LoadingScreen] Error occured in progress animate function. Error: `, e);
                this._onProgressAnimate = null;
            }
    
            if (typeof progress === 'number' && progress >= 0) {
                this._circleProgressBar.setProgress(progress);
            }
        }

        requestAnimationFrame(this._animate);
    }

    setMessage(message: string): LoadingScreen {
        this._messageElement.textContent = message;
        return this;
    }
}

export const loadingScreen = new LoadingScreen();